import React, {useContext, useEffect, useState} from "react";
import {refillSensor} from "../../../../microservices/sensors";
import {Link} from "react-router-dom";
import {Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import RefreshIcon from '@mui/icons-material/Refresh';
import {ReactComponent as Edit} from '../../../../images/pencil.svg';
import firebase from "firebase";
import {UiButton} from "../../../ui/UiButton";
import {
    calculateBarMessageColor,
    calculateSecondsLeft, calculateSecondsLeftDistance, calculateSensorDistance,
    calculateSensorLiquidLevel,
    fixSensorRefill,
    calculateBatteryPercentage,
    TimeToGo
} from "../../../../services/sensor";
import {alertColours, Sensor, SensorBarLevel, SensorStatus} from "../../../../models/types/sensor";
import {AuthContext} from "../../../../context/AuthContext";
import {useSnackbar} from "notistack";
import {convertToTimestamp} from "../../../../services/utils";
import {UiProgressBar} from "../../../ui/UiProgressBar";

interface Props {
    sensor: Sensor;
    setTags: (tag: string[]) => void;
}

export function SensorTableRow({sensor}: Props) {
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
    const [sensorStatus, setSensorStatus] = useState<SensorStatus>({});
    const user = useContext(AuthContext);
    const {enqueueSnackbar} = useSnackbar();
    const db = firebase.firestore();
    const theme = useTheme();
    const responsiveMatch = useMediaQuery(theme.breakpoints.down('lg'));
    useEffect(() => {
        getSensorStatus(sensor.id);
    }, [sensor]);

    function getSensorStatus(sensorId: string) {
        db.collection(`devices/${sensorId}/status`)
            .where('MessageType', '==', 'regular')
            .orderBy('Timestamp', 'desc')
            .limit(1)
            .onSnapshot(snap => {
                snap.docChanges().forEach(change => {
                    if (change.type === 'added') {
                        setSensorStatus(change.doc.data());
                    }
                });
            });
    }

    let barLevel: SensorBarLevel;

    if (sensorStatus.Distance) {
        barLevel = calculateSensorDistance(sensor, sensorStatus);
        if (sensor.containerType === 'recycling-bin' && sensor && sensor.alerts && sensor.alerts.doses) {
            sensor.alerts.doses.empty = 100 - sensor.alerts.doses.empty;
            sensor.alerts.doses.critical = 100 - sensor.alerts.doses.critical;
        }
    } else {
        barLevel = calculateSensorLiquidLevel(sensor, sensorStatus);
    }

    const timeToGo: TimeToGo = sensorStatus.Distance ? calculateSecondsLeftDistance(sensor, sensorStatus) : calculateSecondsLeft(sensor, sensorStatus);

    const barMessage = barLevel.percentage === -1 ? 'N/A' :
        `${barLevel.percentage}%`;

        // ${timeToGo.seconds !== -1 ?
        // `[${timeToGo.days > 0 ?
        // `${timeToGo.days}d` :
        // ''}${timeToGo.hours > 0 ?
        // ` ${timeToGo.hours}h` :
        // ''}${timeToGo.days === 0 ?
        // ` ${timeToGo.minutes}m` :
        // ''}]` :
        // ''}`;

    const batteryMessageColor = sensor?.alerts?.battery && sensorStatus?.CurrentVoltage ? (sensor.alerts.battery.empty > sensorStatus.CurrentVoltage ? alertColours.empty : sensor.alerts.battery.critical > sensorStatus.CurrentVoltage ? alertColours.critical : alertColours.normal) : '';

    async function refill(sensorId: string, user: firebase.User) {
        try {
            await refillSensor(sensorId, user);
            enqueueSnackbar('We have set this device as refilled, where the sensor-show is placed.', {variant: 'success'});
        } catch (error) {
            enqueueSnackbar('Failed to trigger refill', {variant: 'error'});
        }
        setConfirmationDialogOpen(false);
    }

    if (barLevel.percentage > 100) {
        fixSensorRefill(sensor);
    }

    if (sensor.id === '70B3D57ED004CF75') {
        //console.log('showing this');
    }

    return (<>
        <Grid item xs={6} md={6} lg={2} padding={1}>
            <Box sx={{
                fontWeight: 'bold',
                marginRight: '5px',
                display: {xs: 'inline-block', md: 'inline-block', lg: 'none'}
            }}>Label:</Box>
            <Link to={`/sensor-details/${sensor.id}`}>{sensor.label || sensor.name || sensor.id}</Link>
        </Grid>
        <Grid item xs={6} md={6} lg={1} style={{color: calculateBarMessageColor(sensor, barLevel)}} padding={1}  sx={{
            display:{xs:'none', md:'none',lg: 'block'}
            }}>
            <Box sx={{
                fontWeight: 'bold',
                marginRight: '5px',
                display: {xs: 'inline-block', md: 'inline-block', lg: 'none'}
            }}>consumables:</Box>
            {barMessage}
        </Grid>
        <Grid item xs={6} md={6} lg={2} style={{color: batteryMessageColor}} padding={1} sx={{
            display:{xs:'none', md:'none',lg: 'block'}
            }}>
            <Box sx={{
                fontWeight: 'bold',
                marginRight: '5px',
                display: {xs: 'inline-block', md: 'inline-block', lg: 'none'}
            }}>Battery:</Box>
           <UiProgressBar
                label={``}
                percentage={calculateBatteryPercentage(sensor, sensorStatus)}
                max={5} alerts={sensor?.alerts?.battery} />
        </Grid>
        <Grid container xs={12} md={12} lg={1} columns={{ xs: 12, md: 12 }} padding={1} sx={{
             display:{xs:'flex',md:'flex',lg: 'none'}
            }}>
            <Box sx={{
                fontWeight: 'bold',
                marginRight: '5px',
            }}>Level:</Box>
            <Grid item xs={3} md={5} sx={{color: calculateBarMessageColor(sensor, barLevel)}}>{barMessage}</Grid>
            <Grid item xs={5} md={5} sx={{color: batteryMessageColor}}>
            <UiProgressBar
                label={``}
                percentage={calculateBatteryPercentage(sensor, sensorStatus)}
                max={5} alerts={sensor?.alerts?.battery} />
                </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={2} padding={1}>
            <Box sx={{
                fontWeight: 'bold',
                marginRight: '5px',
                display: {xs: 'inline-block', md: 'inline-block', lg: 'none'}
            }}>Last reported:</Box>
            {sensorStatus && sensorStatus.Timestamp && convertToTimestamp(sensorStatus.Timestamp)}
        </Grid>
        <Grid item xs={12} md={12} lg={3} padding={1} sx={{
            display:{xs:'none',md:'none',lg: 'block'}
            }}>
            <Box sx={{
                fontWeight: 'bold',
                marginRight: '5px',
                display: {xs: 'inline-block', md: 'inline-block', lg: 'none'}
            }}>Tags:</Box>
            {sensor.tags?.map((tag: string, index: number) => <span key={index}>{tag} {' '}</span>)}
        </Grid>
        <Grid item xs={12} md={12} lg={2} padding={1} sx={{
            textAlign: {lg:'right', md:'left'},
            paddingBottom: '20px',
            borderBottomStyle: 'solid',
            borderBottomWidth: {xs: '1px', md: '1px', lg: '0px'}
        }}>
            <UiButton
                icon={<Link to={`/sensor-details/${sensor.id}`}><Edit
                    style={{height: '20px', verticalAlign: 'middle'}}/></Link>}
                    style={{ padding: responsiveMatch ? "5px 10px" : "10px" }}
            />
            <UiButton
                icon={<RefreshIcon style={{height: '20px', verticalAlign: 'middle',fill:"#FFBC00" }}/>}
                onClick={() => setConfirmationDialogOpen(true)}
                style={{ marginLeft: '10px', padding: responsiveMatch ? "5px 10px" : "10px" }}
            />
            <Dialog
                open={confirmationDialogOpen}
                onClose={() => setConfirmationDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Please confirm, that you have refilled the device!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">In order to give the best service, we would like to make sure
                    that you have properly filled the liquid tank and we could reset the counter for this.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <UiButton
                        onClick={() => setConfirmationDialogOpen(false)}
                        name="Cancel"
                        style={{ marginBottom: '10px', marginRight: '10px' }}
                    />
                    <UiButton
                        onClick={() => refill(sensor.id || '', user || {} as firebase.User)}
                        name="Confirm"
                        icon={<RefreshIcon style={{height: '20px', verticalAlign: 'middle'}}/>}
                        style={{ marginBottom: '10px', marginRight: '10px' }}
                    />
                </DialogActions>
            </Dialog>
        </Grid>
    </>);
}
