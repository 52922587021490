import React, {useContext, useEffect, useState} from "react";
import firebase from "firebase";
import {SensorsTable} from "./table/SensorsTable";
import {AuthContext} from "../../context/AuthContext";
import {UiButton} from "../ui/UiButton";
import {WelcomeRoutes} from "../../services/configuration";
import {Autocomplete, Grid,TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Sensor, SensorStatus, SensorExport} from "../../models/types/sensor";
import {AppSkin} from "../../services/configuration";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const excelJS = require("exceljs");

export function Sensors() {
    const [sensors, setSensors] = useState<Sensor[]>([]);
    const [sensorsToShow, setSensorsToShow] = useState<Sensor[]>([]);
    const [search, setSearch] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const [selectedFilterTags, setSelectedFilterTags] = useState<string[]>([]);
    const user = useContext(AuthContext);
    const db = firebase.firestore();
    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getSensors();
    }, []);

    useEffect(() => {
        setSensorsToShow(sensors.filter(sensor => {
            if (selectedFilterTags.length > 0) {
                const foundTag = selectedFilterTags.map(tag => sensor?.tags?.includes(tag));
                if (selectedFilterTags.length !== foundTag.filter(Boolean).length) {
                    return false;
                }
            }

            if (search) {
                // Use 'name' if available, otherwise use 'label'
                const displayName = sensor.name || sensor.label;

                const nameMatch = displayName && displayName.toLowerCase().includes(search.toLowerCase());
                const idMatch = sensor.id && sensor.id.toLowerCase().includes(search.toLowerCase());

                if (!(nameMatch || idMatch)) {
                    return false;
                }
            }

            return true;
        }));
    }, [sensors, search, selectedFilterTags]);

    function toggleFilterTag(tag: string) {
        const currentSelectedTagList = [...selectedFilterTags];
        const index = currentSelectedTagList.indexOf(tag);
        if (index >= 0) {
            currentSelectedTagList.splice(index, 1);
        } else {
            currentSelectedTagList.push(tag);
        }
        setSelectedFilterTags(currentSelectedTagList);
    }

    function exportSensor() {
        if(!loading){
            setLoading(true);
            getSensors();
            getSensorStatus(selectedDate, selectedEndDate);
        }
    }

    function hourlyExportSensor(){
        if(!loading){
            setLoading(true);
            getSensors();
            getSensorStatus(selectedDate, selectedEndDate, 0);
        }
    }

    function getSensors() {
        if (!user) {
            return;
        }
        db.collection('devices').where('users', 'array-contains', user.uid).onSnapshot(snapshot => {
            const sensorList: any[] = [];
            const tagList: Set<string> = new Set();
            snapshot.forEach(async doc => {
                const sensor: Sensor = doc.data() as Sensor;
                sensor.tags?.forEach((tag: string) => tagList.add(tag));
                sensorList.push(sensor);
            });
            setTags(Array.from(tagList.values()));
            setSensors(sensorList);
        });
    }

    async function getSensorStatus(selectedDate: Date, selectedEndDate: Date, limit: number = 300) {
        const sensExp: any[] = [];
        if (!user) {
             return;
        }
        let counter = 1;
        if(!selectedDate){
            enqueueSnackbar('Please select a from date', {variant: 'error'});
            setLoading(false);
            return;
        }
        selectedEndDate.setHours(23,59,59,999);
        await Promise.all (
         sensorsToShow.map (async (sensor) => {
            let query = db.collection(`devices/${sensor.id}/status`)
            .where('Timestamp', '>=', selectedDate)
            .where('Timestamp', '<=', selectedEndDate)
            .orderBy('Timestamp', 'desc')
            if(limit != 0 ){
                query.limit(limit);
            }
            //console.log(limit);
            const statuses: SensorStatus[] = (await query.get()).docs.map(doc => doc.data());
            let greatestOpenCount = 0;
           statuses.forEach( (status) => {
            let currentSensorExp = sensExp[sensExp.length-1];
            if(sensor.Type === 'window' && new Date(currentSensorExp?.Timestamp).getHours() === new Date(status?.Timestamp?.seconds * 1000 + status?.Timestamp?.nanoseconds / 1000000).getHours() && currentSensorExp?.Serial === sensor.Serial && limit === 0){
                // sensExp[sensExp.length-1].OpenCount += status.doorOpenTime;
                sensExp[sensExp.length-1].Timestamp = new Date(new Date(status?.Timestamp?.seconds * 1000+ status?.Timestamp?.nanoseconds / 1000000).setMinutes(0, 0, 0)).toLocaleString();
                if(greatestOpenCount === 0){
                    greatestOpenCount = currentSensorExp.OpenCount;
                }
                let nrOfVisits = Math.round((greatestOpenCount - status.doorOpenTime) / 2);
                nrOfVisits = nrOfVisits <= 0 ? 1 : nrOfVisits;
                sensExp[sensExp.length-1].nrOfVisits = nrOfVisits; 
                 
                return;
                
            }
            else if(limit === 0){
                greatestOpenCount = 0;
                if(!sensExp[sensExp.length-1]?.nrOfVisits){
                    sensExp.splice(sensExp.length-1);
                }
            }
            const sensorsExport: SensorExport = {
                id: counter,
                name: sensor.label,
                tags: sensor.tags,
                type: sensor.Type,
                Serial: sensor.Serial,
                AllowedCom: status.AllowedCom,
                Distance: status.Distance,
                CurrentVoltage: status.CurrentVoltage,
                Timestamp: new Date(status?.Timestamp?.seconds * 1000+ status?.Timestamp?.nanoseconds / 1000000).toLocaleString(), //status.Timestamp,
                Latitude: sensor.coordinates?.y?.toString(),
                Longitude: sensor.coordinates?.x?.toString(),
                DoorStatus: status.doorStatus,
                Duration: status.doorOpenDuration,
                OpenCount: status.doorOpenTime
           }
            if(sensor.Type === 'window' && limit === 0){
               sensExp.push(sensorsExport);
               counter++;
               delay (1000);
            }
            else if(limit !== 0){
                sensExp.push(sensorsExport);
                counter++;
                delay (1000);
            }
          });

        }));
        // kui on mere pst sensor 2 ja selecteddate ja selectedenddate on vahemikus 2023-12-01 kuni 2024-04-01 siis lisa juurde paar rida exporti
        if(limit === 0 && selectedDate >= new Date('2023-12-01') && selectedEndDate <= new Date('2024-04-01')){
            // include extradata.json and load it to a variable
            const extraData = require('./extradata.json');
            extraData.forEach((data: any) => {
                const extradate = new Date(data.timestamp);
                if(extradate >= selectedDate && extradate <= selectedEndDate){
                    const sensorsExport: SensorExport = {
                        id: counter,
                        name: data.label,
                        tags: ["NOVATER"],
                        type: "window",
                        Serial: data.id,
                        AllowedCom: "helium",
                        Distance: null,
                        CurrentVoltage: 0,
                        Timestamp: extradate.toLocaleString(), 
                        Latitude: null,
                        Longitude: null,
                        DoorStatus: 0,
                        Duration: 0,
                        OpenCount: data.openCount,
                        nrOfVisits: data.nrOfVisits
                   }
                    sensExp.push(sensorsExport);
                    counter++;
                }
            });

        }

        getExcelExport(sensExp, limit);

    }

    function delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    function getDate(){
        let dt = new Date();
       return dt;
    }

    function getExcelExport(sensorExport: any[], limit: number = 300) {

        const workbook = new excelJS.Workbook();  // Create a new workbook
        const worksheet = workbook.addWorksheet("Sensor Data"); // New Worksheet
        const path = "./files";  // Path to download excel

        if(limit === 0){
            worksheet.columns = [
                { header: "Id", key: "id", width: 10 },
                { header: "Name", key: "name", width: 10 },
                { header: "Sensor Type", key: "type", width: 10 },
                { header: "Sensor Serial", key: "Serial", width: 10 },
                { header: "Last Online Time", key: "Timestamp", width: 10 },
                { header: "Nr of visits", key: "nrOfVisits", width: 10},
                ];

                sensorExport.forEach((value) => {
                    worksheet.addRow([value.id, value.name, value.type, value.Serial, value.Timestamp, value.nrOfVisits]);
                });
        
        } else {
            worksheet.columns = [
            { header: "Id", key: "id", width: 10 },
            { header: "Name", key: "name", width: 10 },
            { header: "Tags", key: "tags", width: 10 },
            { header: "Sensor Type", key: "type", width: 10 },
            { header: "Sensor Serial", key: "Serial", width: 10 },
            { header: "Network", key: "AllowedCom", width: 10 },
            { header: "Level", key: "Distance", width: 10 },
            { header: "Battery", key: "CurrentVoltage", width: 10 },
            { header: "Last Online Time", key: "Timestamp", width: 10 },
            { header: "Latitude", key: "Latitude", width: 10 },
            { header: "Longitude", key: "Longitude", width: 10},
            { header: "Door Status", key: "Door Status", width: 15},
            { header: "Duration(Last Open)", key: "Duration(Last Open)", width: 20},
            { header: "Open Count", key: "Open Count", width: 10},
            { header: "Nr of visits", key: "nrOfVisits", width: 10},
            ];

            sensorExport.forEach((value) => {
                worksheet.addRow([value.id, value.name, value.tags, value.type, value.Serial, value.AllowedCom, value.Distance, value.CurrentVoltage, value.Timestamp, value.Latitude, value.Longitude, value.DoorStatus, value.Duration, value.OpenCount, value.nrOfVisits]);
            });
    
        }


        worksheet.getRow(1).eachCell((cell: any) => {
          cell.font = { bold: true };
        });

        workbook.xlsx.writeBuffer().then((data: any) => {
          //console.log("buffer");
          const blob = new Blob([data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = "sensors_export.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          setLoading(false);
       });

    }

    if (!sensors.length) {
        return <div  style={{textAlign: 'center'}}>
            <div>You don't seem to have any sensors</div>
            <div style={{ marginTop: '2rem' }}><UiButton name="add device" onClick={() => navigate(WelcomeRoutes.NEW_SENSOR) } /></div>
        </div>;
    }

    return (<>
        <Grid container xs={12} md={12} lg={12}>
            <Grid item xs={6} sm={4} md={4} lg={12} style={{marginTop: '15px'}}>
                <h2>Sensors</h2>
            </Grid>
            <Grid item xs={6} sm={8} md={8} lg={12} style={{textAlign: 'left', marginTop: '20px', paddingRight:'20px'}} sx={{ paddingLeft:{xs:'0',md:'0',lg:'20px'}, display:{xs:'block',md:'block',lg:'block'}}}>
                    <TextField
                        variant="outlined"
                        label="Search"
                        value={search || ''}
                        onChange={(value) => setSearch(value.target.value)}
                        style={{width:'100%'}}
                    />
            </Grid>
        </Grid>
        <div style={{textAlign: 'right', margin: '20px'}}>
            <span style={{padding: '10px', display:"inline-block"}}>From: <DatePicker popperClassName={"z3"} selected={selectedDate} onChange={date => setSelectedDate(date)} maxDate= {getDate()} dateFormat="dd/MM/yyyy" /></span>
            <span style={{padding: '10px', display:"inline-block"}}> To: <DatePicker popperClassName={"z3"} selected={selectedEndDate} onChange={date => setSelectedEndDate(date)} maxDate= {getDate()} dateFormat="dd/MM/yyyy" /></span>
            {loading ? <span style={{padding: '10px'}}>Loading...</span> : 
            <>
                { AppSkin !== 'novater' && <span style={{padding: '10px'}}><UiButton
                        name = "Export"
                        onClick={exportSensor}
                /></span>
                }
                { AppSkin === 'novater' &&
                    <span style={{padding: '10px'}}><UiButton
                        name = "Hourly export"
                        onClick={hourlyExportSensor}
                    /></span>
                }
            </>}
        </div>
        <div style={{margin: '20px'}}>
        </div>
        <Grid style={{textAlign: 'left', margin: '20px'}} sx={{display:{xs:'none',md:'none',lg:'inline-block'}}}>
            <span style={{padding: '10px'}}>Filter sensor location tags: </span>
            {!tags.length && <span style={{padding: '10px'}}>none of your sensors has any location tags set</span>}
            {tags.map(tag => <span key={tag}>
                <span className="likeALink"
                      key={tag}
                      onClick={() => toggleFilterTag(tag)}
                      style={{
                          cursor: 'pointer',
                          borderRadius: '10px',
                          borderStyle: selectedFilterTags.indexOf(tag) >= 0 ? 'solid' : 'none',
                          borderWidth: '1px',
                          borderColor: 'green',
                          padding: '3px'
                      }}
                >{tag}</span> {' '}</span>)}
        </Grid>
        <Grid style={{textAlign: 'left', margin: '20px'}} sx={{display:{xs:'block',md:'block',lg:'none'}}}>
            <Autocomplete
                multiple
                value={selectedFilterTags}
                onChange={(event: any, newValue: string[]) => {
                    setSelectedFilterTags(newValue);
                }}
                id="multiple-tags"
                options={tags}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                    <TextField {...params} label="Location Tags Filter" placeholder="Location Tags Filter" />
                )}
                style={{paddingLeft: '10px'}}
             />
        </Grid>
        <SensorsTable sensors={sensorsToShow} setTags={setTags}/>
    </>);
}